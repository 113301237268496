import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { officeList, officeDelete } from "../../../store/actions/officeActions";
import { Popconfirm, message, Empty } from "antd";
import { MaskEmail, MaskPhoneNumber } from "../../../utils/helper";
import TableWrapper from "../../../components/Tables";
import Pagination from "../../../components/Pagination";

const OfficeIndex = () => {
  const [search, setsearch] = useState("");
  let dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    dispatch(officeList(1, search));
    localStorage.removeItem("id_office");
  }, [dispatch]);

  const dataofficeList = useSelector((state) => state.officeList);
  const { office, listsOffice } = dataofficeList;

  const dataLogin = useSelector((state) => state.login);
  let { userInfo } = dataLogin;

  const handleEdit = (id) => {
    localStorage.setItem("id_office", id);
    history.push("/office-edit");
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(officeList(1, search));
  };

  const tableHead = [
    { name: "No" },
    { name: "Name" },
    { name: "City" },
    { name: "Province" },
    { name: "Email" },
    { name: "Phone" },
    { name: "Status" },
    { name: "Action" },
  ];

  const tableBody = [
    { scope: "row" },
    { render: (item) => item.name },
    { render: (item) => item.city },
    { render: (item) => item.province },
    { render: (item) => <MaskEmail item={item} /> },
    { render: (item) => <MaskPhoneNumber item={item} /> },
    {
      render: (item) => (
        <span className={`badge badge-${item.active ? "success" : "danger"}`}>
          {item.active ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      render: (item) => (
        <div align="center">
          {userInfo.permission.find((e) => e === "office-edit") ? (
            <button
              onClick={() => handleEdit(item.id)}
              className="btn btn-warning btn-sm"
            >
              <i className="fa fa-pencil"></i> Edit{" "}
            </button>
          ) : null}
          {userInfo.permission.find((e) => e === "office-delete") ? (
            <Popconfirm
              title="Are you sure to delete this parent?"
              onConfirm={() =>
                dispatch(officeDelete(item.id)).then(() =>
                  dispatch(officeList(1, search))
                )
              }
              onCancel={() => message.info("Canceled!")}
              okText="Yes"
              cancelText="No"
            >
              <button className="btn btn-danger btn-sm">
                <i className="fa fa-trash-o"></i> Delete{" "}
              </button>
            </Popconfirm>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Office</h3>
        </div>
      </div>
      <div className="clearfix"></div>
      <br />
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12 overflow-hidden">
          <div className="x_panel">
            <div className="x_title">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12">
                  {userInfo.permission.find((e) => e === "office-create") ? (
                    <Link to={"/office-create"} className="btn btn-info btn-sm">
                      Create
                    </Link>
                  ) : null}
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12 form-group has-feedback">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search By Name"
                    style={{ float: "right", width: "50%" }}
                    onChange={(e) => setsearch(e.target.value)}
                  />
                  <button
                    className="fa fa-search form-control-feedback right btn btn-link"
                    style={{
                      height: "100%",
                      margin: "auto",
                      textDecoration: "none",
                    }}
                    aria-hidden="true"
                    onClick={(e) => handleSearch(e)}
                  ></button>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>

            <div className="x_content overflow-auto">
              <TableWrapper 
                datas={listsOffice}
                tableHead={tableHead}
                tableBody={tableBody}
              />
              <Pagination
                datas={office}
                listsData={officeList}
                search={search}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfficeIndex;
