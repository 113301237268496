import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { developerAll } from '../../../store/actions/developerActions'
import { leadWebsiteEdit, leadWebsiteUpdate } from '../../../store/actions/leadWebsiteActions'
import { Row, Select } from 'antd';
const LeadWebsiteEdit = () => {
  const [name, setname] = useState('')
  const [email, setemail] = useState('')
  const [no_telepon, setno_telepon] = useState('')

  const [search, setsearch] = useState('')
  let dispatch = useDispatch()
  let dispatchOf = useDispatch()
  let history = useHistory()
  const id = localStorage.getItem('lead_website_id');

  const { Option } = Select;

  useEffect(() => {
    dispatch(leadWebsiteEdit(id))
    dispatchOf(developerAll(1, search))
  }, [dispatch, dispatchOf, id])

  const leadWebsiteUpdated = useSelector(state => state.leadWebsiteUpdate)
  const { validation } = leadWebsiteUpdated

  const dataLeadWebsite = useSelector(state => state.leadWebsiteEdit)
  const { leadWebsite } = dataLeadWebsite

  useMemo(() => {
    if (leadWebsite) {
      setname(leadWebsite.name)
      setemail(leadWebsite.email)
      setno_telepon(leadWebsite.phone)
    }
  }, [leadWebsite])

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(leadWebsiteUpdate(name, email, no_telepon, id, history))
  }

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Edit Lead Website</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <h2>Form Input</h2>
              <div className="clearfix"></div>
            </div>
            
            <div className="x_content">  
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label for="name">Name</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['name'] ? 'form-control parsley-error' : 'form-control' }
                        onChange={(e) => setname(e.target.value)}
                        value={name}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['name']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label for="email">Email</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['email'] ? 'form-control parsley-error' : 'form-control' }
                        onChange={(e) => setemail(e.target.value)}
                        value={email}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['email']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label for="phone">Phone</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['phone'] ? 'form-control parsley-error' : 'form-control' }
                        onChange={(e) => setno_telepon(e.target.value)}
                        value={no_telepon}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['phone']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  
                </div>
                
                <button type="submit" className="btn btn-primary pull-right">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeadWebsiteEdit;
