import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  adminbranchList,
  adminbranchDelete,
} from "../../../../store/actions/memberActions";
import { Popconfirm, message, Empty } from "antd";
import { MaskEmail } from "../../../../utils/helper";
import TableWrapper from "../../../../components/Tables";
import Pagination from "../../../../components/Pagination";

const AdminBranchIndex = () => {
  const [search, setsearch] = useState("");
  let dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    dispatch(adminbranchList(1, search));
    localStorage.removeItem("id_admin-branch");
  }, [dispatch]);

  const dataadminbranchList = useSelector((state) => state.adminbranchList);
  const { adminbranch, listsAdminBranch } = dataadminbranchList;

  const dataLogin = useSelector((state) => state.login);
  let { userInfo } = dataLogin;

  const handleEdit = (id) => {
    localStorage.setItem("id_admin-branch", id);
    history.push("/admin-branch-edit");
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(adminbranchList(1, search));
  };

  const tableHead = [
    { name: "No" },
    { name: "Name" },
    { name: "Office" },
    { name: "Email" },
    { name: "Join Date" },
    { name: "Status" },
    { name: "Action" },
  ];

  const tableBody = [
    { scope: "row" },
    { render: (item) => item.name },
    { render: (item) => item.office },
    { render: (item) => <MaskEmail item={item} /> },
    { render: (item) => item.join_date },
    {
      render: (item) => (
        <span
          className={item.active ? "badge badge-success" : "badge badge-danger"}
        >
          {item.active ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      render: (item) => (
        <div align="center">
          {userInfo.permission.find((e) => e === "admin-branch-edit") ? (
            <button
              onClick={() => handleEdit(item.id)}
              className="btn btn-warning btn-sm"
            >
              <i className="fa fa-pencil"></i> Edit{" "}
            </button>
          ) : null}
          {userInfo.permission.find((e) => e === "admin-branch-delete") ? (
            <Popconfirm
              title="Are you sure to delete this parent?"
              onConfirm={() =>
                dispatch(adminbranchDelete(item.id)).then(() =>
                  dispatch(adminbranchList(1, search))
                )
              }
              onCancel={() => message.info("Canceled!")}
              okText="Yes"
              cancelText="No"
            >
              <button className="btn btn-danger btn-sm">
                <i className="fa fa-trash-o"></i> Delete{" "}
              </button>
            </Popconfirm>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Admin Branch</h3>
        </div>
      </div>
      <div className="clearfix"></div>
      <br />
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12">
                  {userInfo.permission.find(
                    (e) => e === "admin-branch-create"
                  ) ? (
                    <Link
                      to={"/admin-branch-create"}
                      className="btn btn-info btn-sm"
                    >
                      Create
                    </Link>
                  ) : null}
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12 form-group has-feedback">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search By Name"
                    style={{ float: "right", width: "50%" }}
                    onChange={(e) => setsearch(e.target.value)}
                  />
                  <button
                    className="fa fa-search form-control-feedback right btn btn-link"
                    style={{
                      height: "100%",
                      margin: "auto",
                      textDecoration: "none",
                    }}
                    aria-hidden="true"
                    onClick={(e) => handleSearch(e)}
                  ></button>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>

            <div className="x_content">
              <TableWrapper
                datas={listsAdminBranch}
                tableHead={tableHead}
                tableBody={tableBody}
              />
              <Pagination
                datas={adminbranch}
                listsData={adminbranchList}
                search={search}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminBranchIndex;
