import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  leadWebsiteDelete,
  leadWebsiteList,
  leadWebsiteUpdateStatus,
  leadWebsiteOfficeEdit,
  leadWebsiteBulkOfficeApply,
  leadWebsiteBulkChangeStatus,
  leadWebsiteRemarks,
} from "../../../store/actions/leadWebsiteActions";
import {
  message,
  Dropdown,
  Space,
  Button,
  Popover,
  Modal,
  Input,
} from "antd";
import {
  convertNumberPhone,
  dateFormat,
  IDRFormat,
  MaskEmail,
  MaskPhoneNumber,
} from "../../../utils/helper";
import DeleteConfirm from "../../../components/DeleteConfirm";
import TableWrapper from "../../../components/Tables";
import ModalGeneric from "../../../components/Modals";
import Pagination from "../../../components/Pagination";
import TextArea from "antd/lib/input/TextArea";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { useFilter } from "../../../hooks/useFilter";

const FormDetails = (props) => {
  const {modalDetail} = props
  return (
    <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
            height: "100%",
          }}
        >
          <div style={{ width: "100%" }}>
            <label for="office">Office</label>
            <Input
              style={{ textAlign: "center", color: 'gray' }}
              value={
                modalDetail?.data?.office
                  ? modalDetail?.data?.office
                  : "-"
              }
              disabled
            />
          </div>
          <div style={{ display: "flex", gap: "10px", width: "100%" }}>
            <div style={{ width: '100%'}}>
              <label for="name">Name</label>
              <Input value={modalDetail?.data?.name} style={{ color: 'gray'}} disabled />
            </div>
            <div style={{ width: '100%'}}>
              <label for="mitra">Mitra</label>
              <Input style={{ color: 'gray'}} value={modalDetail?.data?.mitra} disabled />
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px", width: "100%" }}>
            <div style={{ width: '100%'}}>
              <label for="email">Email</label>
              <Input style={{ color: 'gray'}} value={modalDetail?.data?.email} disabled />
            </div>
            <div style={{ width: '100%'}}>
              <label for="phone">Phone</label>
              <Input style={{ color: 'gray'}} value={modalDetail?.data?.phone} disabled />
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px", width: "100%" }}>
            <div style={{ width: "100%" }}>
              <label for="created_at">Created At</label>
              <Input
              style={{ color: 'gray'}}
                value={dateFormat(modalDetail?.data?.created_at)}
                disabled
              />
            </div>
            <div style={{ width: "100%" }}>
              <label for="updated_at">Updated At</label>
              <Input
              style={{ color: 'gray'}}
                value={dateFormat(modalDetail?.data?.updated_at)}
                disabled
              />
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px", width: "100%" }}>
            <div style={{ width: '100%'}}>
              <label for="updated_by">Updated By</label>
              <Input style={{ color: 'gray'}} value={modalDetail?.data?.updated_by || "-"} disabled />
            </div>
            <div style={{ width: '100%'}}>
              <label for="updated_by">Property Price</label>
              <Input
              style={{ color: 'gray'}}
                value={IDRFormat(modalDetail?.data?.property_price)}
                disabled
              />
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px", width: "100%" }}>
            <div style={{ width: '100%'}}>
              <label for="updated_by">Loan Amount</label>
              <Input
              style={{ color: 'gray'}}
                value={IDRFormat(modalDetail?.data?.loan_amount)}
                disabled
              />
            </div>
            <div style={{ width: '100%'}}>
              <label for="updated_by">Down Payment</label>
              <Input
              style={{ color: 'gray'}}
                value={IDRFormat(modalDetail?.data?.down_payment)}
                disabled
              />
            </div>
          </div>
          <div>
            <label for="remarks">Remarks</label>
            <TextArea
            style={{ color: 'gray'}}
              value={
                modalDetail?.data?.remarks ? modalDetail?.data?.remarks : "-"
              }
              disabled
            />
          </div>
        </div>
  )
};

const LeadWebsite = () => {
  const {getQueryParam, resetQueryParams} = useQueryParams();
  let dispatch = useDispatch();
  let history = useHistory();
  const queryStatus = getQueryParam("status");
  const queryOffice = getQueryParam("office_id");
  const queryMitra = getQueryParam("mitra_id");
  const [search, setsearch] = useState("");
  const [isOffice, setIsOffice] = useState("");
  const [status, setStatus] = useState("0");
  const [isModalEdit, setIsModalEdit] = useState({
    shown: false,
    id: "",
  });
  const [isModalApply, setIsModalApply] = useState({
    shown: false,
    id: "",
  });
  const [isModalApplyBulk, setIsModalApplyBulk] = useState({
    shown: false,
    id: [],
  });
  const [isModalChangeStatusBulk, setIsModalChangeStatusBulk] = useState({
    shown: false,
    data: [],
  });
  const [modalRemarks, setModalRemarks] = useState({
    shown: false,
    id: "",
    content: "",
  });
  const [modalDetail, setModalDetail] = useState({
    shown: false,
    data: {},
  });
  const dataleadWebsiteList = useSelector((state) => state.leadWebsite);
  const { leadWebsite, leadWebsites, office, mitra } = dataleadWebsiteList;
  const [valueRemarks, setValueRemarks] = useState("");
  const {leadWebsiteFilter} = useFilter();
  const dataLogin = useSelector((state) => state.login);
  let { userInfo } = dataLogin;
  const style = { backgroundColor: "transparent", border: "none" };

  const [isChecked, setIsChecked] = useState([]);
  const isCheckedAll = isChecked.length === leadWebsites.length;

  const isStatus = [
    {
      id: 0,
      name: "Cold",
      color: "#ef4444",
    },
    {
      id: 1,
      name: "Warm",
      color: "#f59e0b",
    },
    {
      id: 2,
      name: "Hot",
      color: "#22c55e",
    },
  ];

  const items = (id) => {
    return [
      {
        key: "1",
        label: (
          <Button
            type="button"
            style={style}
            onClick={() =>
              setIsModalEdit({ ...isModalEdit, id: id, shown: true })
            }
          >
            Edit Status
          </Button>
        ),
      },
      {
        key: "2",
        label: (
          <Button
            type="button"
            style={style}
            onClick={() =>
              setIsModalApply({ ...isModalApply, id: id, shown: true })
            }
          >
            Apply Office
          </Button>
        ),
      },
      {
        key: "3",
        label: (
          <Button
            type="button"
            style={style}
            onClick={handleEditData}
          >
            Edit Lead Website
          </Button>
        ),
      },
    ];
  };

  const handleRemarks = (id) => {
    setModalRemarks({
      shown: true,
      id: id,
      content: leadWebsites?.find((item) => item.id === id)?.remarks,
    });
  };

  const tableHead = [
    { name: "All", checkbox: true },
    { name: "Mitra" },
    { name: "Name" },
    { name: "Email" },
    { name: "Phone" },
    { name: "Remarks" },
    { name: "Status" },
    { name: "Action" },
  ];

  const tableBody = [
    {
      component: (item) => (
        <input
          type="checkbox"
          className="form-check-input p-0 m-0 position-static"
          onChange={() => handleChecked(item)}
          checked={isChecked?.includes(item)}
        />
      ),
      align: "center",
    },
    { render: (item) => item.mitra },
    { render: (item) => item.name },
    { render: (item) => <MaskEmail item={item} /> },
    { render: (item) => <MaskPhoneNumber item={item} /> },
    {
      render: (item) => (
        <div
          align="center"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <p>{item?.remarks ? (item?.remarks?.length >= 20 ? item?.remarks?.slice(0, 20) + "..." : item?.remarks) : "-"}</p>
          <i
            className="fa fa-edit text-primary"
            style={{ cursor: "pointer" }}
            onClick={() => handleRemarks(item?.id)}
          ></i>
        </div>
      ),
    },
    {
      render: (item) => (
        <div
          style={{
            backgroundColor: isStatus.find((e) => e.id === item.status)?.color,
            color: "white",
            padding: "5px",
            borderRadius: "5px",
          }}
          align="center"
        >
          {isStatus.find((e) => e.id === item.status)?.name}
        </div>
      ),
    },
    {
      render: (item) => (
        <div style={{ display: "flex", gap: "", alignItems: 'center' }}>
          <div style={{ display: "flex" }}>
            {userInfo.permission.find((e) => e === "leads-website-delete") ? (
              <DeleteConfirm
                title={`Are you sure to delete ${item.name}`}
                onConfirm={() => handleDelete(item.id)}
                onCancel={() => message.info("Canceled!")}
              />
            ) : null}
            <button type="button" className="btn btn-link" onClick={() => showDetail(item)}>
              <i className="fa fa-eye" aria-hidden="true"/>
            </button>
          </div>
          <Dropdown trigger={["click"]} menu={{ items: items(item.id) }}>
            <Space style={{ cursor: "pointer" }}>
              <i className="fa fa-ellipsis-v" aria-hidden="true"/>
            </Space>
          </Dropdown>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(leadWebsiteList(1, search, queryStatus, queryOffice, queryMitra));
  }, [dispatch, queryMitra, queryOffice, queryStatus, search]);

  // Handling Actions

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(leadWebsiteList(1, search));
  };


  const resetFilter = () => {
    resetQueryParams();
    return dispatch(leadWebsiteList(1, search));
  };

  const handleCheckedAll = () => {
    if (isCheckedAll) {
      setIsChecked([]);
    } else {
      setIsChecked(leadWebsites);
    }
  };

  const handleChecked = (data) => {
    setIsChecked((prev) => {
      if (prev.includes(data)) {
        return prev.filter((id) => id !== data);
      } else {
        return [...prev, data];
      }
    });
  };

  // Handling API Actions

  const handleEditData = (id) => {
    localStorage.setItem("lead_website_id", id);
    history.push(`leads-website-edit`);
  };

  const handleDelete = (id) => {
    dispatch(leadWebsiteDelete(id)).then(() => {
      dispatch(leadWebsiteList(1, search));
    });
  };

  const handleOkStatus = () => {
    const id = isModalEdit.id;
    dispatch(leadWebsiteUpdateStatus(id, status?.toString()));
    setTimeout(() => {
      setIsModalEdit({
        id: "",
        shown: false,
      });
      dispatch(leadWebsiteList(1, search));
    }, 1000);
  };

  const handleCancelStatus = () => {
    setIsModalEdit({
      ...isModalEdit,
      shown: false,
    });
  };

  const handleOkOffice = () => {
    dispatch(leadWebsiteOfficeEdit(isModalApply.id, isOffice)).then((res) => {
      setIsModalApply({
        id: "",
        shown: false,
      });
      dispatch(leadWebsiteList(1, search));
    });
  };

  const handleCancelOffice = () => {
    setIsModalApply({
      shown: false,
      id: "",
    });
  };

  const handleApplyOfficeBulking = () => {
    if (isChecked.length > 0) {
      setIsModalApplyBulk({
        shown: true,
        data: isChecked,
      });
    }
  };

  const handleChangeStatusBulking = () => {
    if (isChecked.length > 0) {
      setIsModalChangeStatusBulk({
        shown: true,
        data: isChecked,
      });
    }
  };

  const handleOkChangeStatusBulk = () => {
    const bulkId = isChecked?.map((item) => item.id);
    dispatch(leadWebsiteBulkChangeStatus(bulkId, status?.toString())).then(() => {
      setTimeout(() => {
        setIsModalChangeStatusBulk({
          data: "",
          shown: false,
        });
        setIsChecked([]);
        dispatch(leadWebsiteList(1, search));
      }, 1000);
    });
  };

  const handleOkOfficeBulk = () => {
    const bulkId = isChecked?.map((item) => item.id);
    dispatch(leadWebsiteBulkOfficeApply(bulkId, isOffice)).then((res) => {
      setTimeout(() => {
        setIsModalApplyBulk({
          data: "",
          shown: false,
        });
        setIsChecked([]);
        dispatch(leadWebsiteList(1, search));
      }, 1000);
    });
  };

  const handleOkRemarks = () => {
    const remarksId = modalRemarks.id;
    dispatch(leadWebsiteRemarks(remarksId, valueRemarks)).then((res) => {
      setTimeout(() => {
        setModalRemarks({
          id: "",
          shown: false,
        });
        dispatch(leadWebsiteList(1, search));
      }, 1000);
    });
  };

  const showDetail = (item) => {
    setModalDetail({
      shown: true,
      data: item,
    });
  };

  return (
    <div className="">
      {/* Modal Edit Status */}
      <ModalGeneric
        title="Change Status"
        contentType="single"
        visible={isModalEdit?.shown}
        onOk={handleOkStatus}
        onCancel={handleCancelStatus}
        options={isStatus}
        onChange={(e) => setStatus(e?.target?.value)}
      />

      {/* Modal Edit Apply Office */}
      <ModalGeneric
        title="Apply Office to CRM"
        contentType="single"
        visible={isModalApply?.shown}
        onOk={handleOkOffice}
        onCancel={handleCancelOffice}
        options={office}
        onChange={(e) => setIsOffice(e?.target?.value)}
      />

      {/* Modal Edit Bulk Apply Office */}
      <ModalGeneric
        title="Apply Office to CRM"
        contentType="bulk"
        visible={isModalApplyBulk?.shown}
        onOk={handleOkOfficeBulk}
        onCancel={() =>
          setIsModalApplyBulk({ ...isModalApplyBulk, shown: false })
        }
        options={office}
        onChange={(e) => setIsOffice(e?.target?.value)}
        datas={leadWebsites}
        filter={isModalApplyBulk?.data}
      />

      {/* Modal Change Status Bulk */}
      <ModalGeneric
        title="Edit Status"
        contentType="bulk"
        visible={isModalChangeStatusBulk?.shown}
        onOk={handleOkChangeStatusBulk}
        onCancel={() =>
          setIsModalChangeStatusBulk({
            ...isModalChangeStatusBulk,
            shown: false,
          })
        }
        options={isStatus}
        datas={leadWebsites}
        filter={isModalChangeStatusBulk?.data}
        onChange={(e) => setStatus(e?.target?.value)}
      />

      {/* Modal Remarks update */}
      <Modal
        title="Remarks"
        open={modalRemarks.shown}
        onOk={handleOkRemarks}
        onCancel={() => setModalRemarks({ ...modalRemarks, shown: false, content: "" })}
      >
        <TextArea
          defaultValue={modalRemarks.content}
          onChange={(e) => setValueRemarks(e.target.value)}
          placeholder="Add Remarks here.."
          autoSize={{ minRows: 3, maxRows: 5 }}
          maxLength={50}
        />
        <div
          style={{
            marginTop: 8,
            textAlign: "right",
            fontSize: 12,
            color: valueRemarks.length >= 50 ? "red" : "gray",
          }}
        >
          {`${valueRemarks.length} / 50 characters`}
        </div>
      </Modal>

      {/* Modal Detail's  */}
      <Modal
        title="Detail Information"
        onCancel={() => setModalDetail({ ...modalDetail, shown: false })}
        open={modalDetail.shown}
        style={{ top: 20 }}
        footer={
          <div style={{ width: "100%", display: "flex" }}>
            <Button
              style={{ width: "100%", backgroundColor: "#1aa6ed" }}
              onClick={() => setModalDetail({ ...modalDetail, shown: false })}
              htmlType="button"
            >
              <span style={{ color: "white" }}>Close</span>
            </Button>
            <Button
              style={{ width: "10%", backgroundColor: "#25d366" }}
              onClick={() =>
                window.open(`https://wa.me/${convertNumberPhone(modalDetail?.data?.phone)}`)
              }
              htmlType="button"
            >
              <i className="fa fa-whatsapp text-white" aria-hidden="true"></i>
            </Button>
          </div>
        }
      >
        <FormDetails modalDetail={modalDetail} />
      </Modal>

      <div className="page-title">
        <div className="title_left">
          <h3>Lead Website</h3>
        </div>
      </div>
      <div className="clearfix"></div>
      <br />
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <div className="row align-items-center">
                <div className="col-md-6 col-sm-12 col-xs-12">
                  {isChecked?.length > 0 ? (
                    <div className="d-flex align-items-center m-0 text-nowrap">
                      <p className="m-0 mr-2 font-weight-bold text-primary ">
                        {isChecked?.length} Selected of{" "}
                        {leadWebsites.length ? leadWebsites.length : 0}
                      </p>
                      <button
                        className="btn btn-primary"
                        onClick={(e) => handleApplyOfficeBulking(e)}
                      >
                        Send to CRM Office
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={(e) => handleChangeStatusBulking(e)}
                      >
                        Change Status
                      </button>
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12 form-group has-feedback d-flex gap-2 justify-content-end">
                  <div
                    className="d-flex align-items-center justify-content-end mr-2"
                    style={{ width: "50%" }}
                  >
                    <Button
                      type="button"
                      className="btn btn-primary m-0"
                      onClick={() => resetFilter()}
                    >
                      <i className="fa fa-refresh mr-1" />
                    </Button>

                    <Popover
                      placement="bottom"
                      overlayClassName="popover-filter"
                      content={
                        <div className="d-flex flex-column">
                          {isStatus.map((item) => (
                            <button
                              type="button"
                              className="btn btn-link text-left"
                              onClick={() =>
                                leadWebsiteFilter(search, item.id?.toString(), null, null)
                              }
                              value={item.id}
                            >
                              {item.name}
                            </button>
                          ))}
                        </div>
                      }
                      title="Status"
                      trigger="click"
                    >
                      <Button>
                        <i className="fa fa-filter" />
                        <span className=" ml-1">
                          Status{" "}
                          {":" + (queryStatus ? " " + queryStatus : " All")}
                        </span>
                      </Button>
                    </Popover>

                    <Popover
                      placement="bottom"
                      overlayClassName="popover-filter"
                      content={
                        <div
                          className="d-flex flex-column overflow-auto"
                          style={{ maxHeight: "240px" }}
                        >
                          {office.map((item) => (
                            <button
                              type="button"
                              className="btn btn-link text-nowrap text-left"
                              onClick={() => leadWebsiteFilter(search, null, item.id, null)}
                              value={item.id}
                            >
                              {item.name}
                            </button>
                          ))}
                        </div>
                      }
                      title="Office"
                      trigger="click"
                    >
                      <Button>
                        <i className="fa fa-filter" />
                        <span className=" ml-1">
                          Office{" "}
                          {":" + (queryOffice ? " " + queryOffice : " All")}
                        </span>
                      </Button>
                    </Popover>

                    <Popover
                      placement="bottom"
                      overlayClassName="popover-filter"
                      content={
                        <div className="d-flex flex-column">
                          {mitra?.map((item) => (
                            <button
                              type="button"
                              className="btn btn-link text-left"
                              onClick={() => leadWebsiteFilter(search, null, null, item.id)}
                              value={item.id}
                            >
                              {item.name}
                            </button>
                          ))}
                        </div>
                      }
                      title="Office"
                      trigger="click"
                    >
                      <Button>
                        <i className="fa fa-filter" />
                        <span className=" ml-1">
                          Mitra {":" + (queryMitra ? " " + queryMitra : " All")}
                        </span>
                      </Button>
                    </Popover>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search By Name"
                    style={{ float: "right", width: "50%" }}
                    onChange={(e) => setsearch(e.target.value)}
                  />
                  <button
                    className="fa fa-search form-control-feedback right btn btn-link"
                    style={{
                      height: "100%",
                      margin: "auto",
                      textDecoration: "none",
                    }}
                    aria-hidden="true"
                    onClick={(e) => handleSearch(e)}
                  ></button>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>

            <div style={{ overflowX: "auto" }}>
              <TableWrapper
                onRowClick={showDetail}
                tableHead={tableHead}
                tableBody={tableBody}
                datas={leadWebsites}
                isStatus={isStatus}
                handleDelete={handleDelete}
                handleCheckedAll={handleCheckedAll}
                isCheckedAll={isCheckedAll}
                items={items}
              />
              <Pagination
                listsData={leadWebsiteList}
                datas={leadWebsite}
                search={search}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadWebsite;
