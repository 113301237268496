import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { childList, childDelete } from '../../../../store/actions/menuActions'
import { Popconfirm, message, Empty } from 'antd'
import TableWrapper from '../../../../components/Tables'
import Pagination from '../../../../components/Pagination'

const ChildIndex = () => {
  const [search, setsearch] = useState('')
  const dispatch = useDispatch();
  const history = useHistory()
  
  const datachildList = useSelector(state => state.childList)
  const { child, listsChild } = datachildList

  const dataLogin = useSelector(state => state.login)
  let { userInfo } = dataLogin

  useEffect(() => {
    dispatch(childList(1, search))
    localStorage.removeItem('id_child')
  }, [dispatch])

  const handleEdit = (id) => {
    localStorage.setItem('id_child', id)
    history.push('/child-edit')
  }

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(childList(1, search));
  }

  const tableHead = [
    { name: 'No' },
    { name: 'Name' },
    { name: 'Parent' },
    { name: 'Access' },
    { name: 'Action' },
  ];

  const tableBody = [
    {
      scope: 'row',
    },
    {
      render : (item) => item.name,
    },
    {
      render : (item) => item.parent
    },
    {
      render : (item) => item.access
    },
    {
      render: (item) => (
        <div align="center">
          {
            userInfo.permission.find(e => e === 'child-edit')
              ?
                <button 
                  onClick={() => handleEdit(item.id)} 
                  className="btn btn-warning btn-sm">
                    <i className="fa fa-pencil"/> Edit 
                </button>
              :
                null
          }
                                
          {
            userInfo.permission.find(e => e === 'child-delete')
              ?
                <Popconfirm
                  title="Are you sure to delete this child?"
                  onConfirm={() => dispatch(childDelete(item.id)).then(() => dispatch(childList(1, search)) ) }
                  onCancel={() => message.info('Canceled!')}
                  okText="Yes"
                  cancelText="No"
                 >
                  <button className="btn btn-danger btn-sm"><i className="fa fa-trash-o"></i> Delete </button>
                </Popconfirm>
          :
          null
          }
        </div>
      )
    }
  ];

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Child</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12">
                  {
                    userInfo.permission.find(e => e === 'child-create')
                    ?
                    <Link to={'/child-create'} className="btn btn-info btn-sm">Create</Link>
                    :
                      null
                  }
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12 form-group has-feedback">
                  <input type="text" className="form-control" placeholder="Search By Name" style={{ float: 'right', width:'50%' }} onChange={(e) => setsearch(e.target.value)} />
                  <button className="fa fa-search form-control-feedback right btn btn-link" style={{ height:'100%', margin:'auto', textDecoration:'none' }} aria-hidden="true" onClick={(e) => handleSearch(e)}></button>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
            
            <div className="x_content">
              <TableWrapper
                tableHead={tableHead}
                tableBody={tableBody}
                datas={listsChild}
                userInfo={userInfo}
                permissionName="child"
              />
              <Pagination datas={child} search={search} listsData={childList} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChildIndex
