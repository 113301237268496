import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  partnerList,
  partnerDelete,
} from "../../../../store/actions/websiteActions";
import { Popconfirm, message, Empty } from "antd";
import TableWrapper from "../../../../components/Tables";
import Pagination from "../../../../components/Pagination";

const PartnerIndex = () => {
  const [search, setsearch] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const datapartnerList = useSelector((state) => state.partnerList);
  const { partner, listsPartner } = datapartnerList;

  const dataLogin = useSelector((state) => state.login);
  let { userInfo } = dataLogin;

  useEffect(() => {
    dispatch(partnerList(1, search));
    localStorage.removeItem("id_partner");
  }, [dispatch]);

  const handleEdit = (id) => {
    localStorage.setItem("id_partner", id);
    history.push("/partner-edit");
  };

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    dispatch(partnerList(pageNumber, search));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(partnerList(1, search));
  };

  const listsPaginate = (jumlah_page, current_page) => {
    let item = [];
    for (let index = 1; index <= jumlah_page; index++) {
      item.push(index);
    }
    console.log(item);
    return item.map((page) => {
      if (current_page === 1) {
        if (page === current_page || page === parseInt(current_page) + 1) {
          return (
            <li
              className={
                current_page === page ? "page-item active" : "page-item"
              }
            >
              <a
                className="page-link"
                href="#"
                onClick={(e) => handlePageChange(e, page)}
              >
                {page}
              </a>
            </li>
          );
        }
      } else if (current_page === jumlah_page) {
        if (page === current_page || page === parseInt(current_page) - 1) {
          return (
            <li
              className={
                current_page === page ? "page-item active" : "page-item"
              }
            >
              <a
                className="page-link"
                href="#"
                onClick={(e) => handlePageChange(e, page)}
              >
                {page}
              </a>
            </li>
          );
        }
      } else {
        if (
          page === parseInt(current_page) - 1 ||
          page === current_page ||
          page === parseInt(current_page) + 1
        ) {
          return (
            <li
              className={
                current_page === page ? "page-item active" : "page-item"
              }
            >
              <a
                className="page-link"
                href="#"
                onClick={(e) => handlePageChange(e, page)}
              >
                {page}
              </a>
            </li>
          );
        }
      }
    });
  };

  const tableHead = [
    { name: "No" },
    { name: "Name" },
    { name: "Status" },
    { name: "Order" },
    { name: "Action" },
  ];

  const tableBody = [
    { scope: "row" },
    { render: (item) => item.name },
    { render: (item) => (
      <div align="center">
        <span className={`badge ${item.active ? 'badge-success' : 'badge-danger'}`}>
          {item.active ? "Active" : "Deactive" }
        </span>
      </div>
    )},
    { render: (item) => item.sort },
    {
      render: (item) => (
        <div align="center">
          {userInfo.permission.find((e) => e === "partner-edit") ? (
            <button
              onClick={() => handleEdit(item.id)}
              className="btn btn-warning btn-sm"
            >
              <i className="fa fa-pencil"></i> Edit{" "}
            </button>
          ) : null}

          {userInfo.permission.find((e) => e === "partner-delete") ? (
            <Popconfirm
              title="Are you sure to delete this partner?"
              onConfirm={() =>
                dispatch(partnerDelete(item.id)).then(() =>
                  dispatch(partnerList(1, search))
                )
              }
              onCancel={() => message.info("Canceled!")}
              okText="Yes"
              cancelText="No"
            >
              <button className="btn btn-danger btn-sm">
                <i className="fa fa-trash-o"></i> Delete{" "}
              </button>
            </Popconfirm>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Partner</h3>
        </div>
      </div>
      <div className="clearfix"></div>
      <br />
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12">
                  {userInfo.permission.find((e) => e === "partner-create") ? (
                    <Link
                      to={"/partner-create"}
                      className="btn btn-info btn-sm"
                    >
                      Create
                    </Link>
                  ) : null}
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12 form-group has-feedback">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search By Name"
                    style={{ float: "right", width: "50%" }}
                    onChange={(e) => setsearch(e.target.value)}
                  />
                  <button
                    className="fa fa-search form-control-feedback right btn btn-link"
                    style={{
                      height: "100%",
                      margin: "auto",
                      textDecoration: "none",
                    }}
                    aria-hidden="true"
                    onClick={(e) => handleSearch(e)}
                  ></button>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>

            <div className="x_content">
              <TableWrapper
                datas={listsPartner}
                tableHead={tableHead}
                tableBody={tableBody}
              />
              <Pagination
                datas={partner}
                listsData={partnerList}
                search={search}
              />
              {/* <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                  <li
                    className={
                      partner.prev_page_url === null
                        ? "page-item disabled"
                        : "page-item"
                    }
                  >
                    <a
                      className="page-link"
                      href="#"
                      onClick={(e) =>
                        handlePageChange(e, parseInt(partner.current_page) - 1)
                      }
                      tabindex="-1"
                    >
                      Previous
                    </a>
                  </li>
                  {listsPaginate(partner.last_page, partner.current_page)}
                  <li
                    className={
                      partner.next_page_url === null
                        ? "page-item disabled"
                        : "page-item"
                    }
                  >
                    <a
                      className="page-link"
                      href="#"
                      onClick={(e) =>
                        handlePageChange(e, parseInt(partner.current_page) + 1)
                      }
                    >
                      Next
                    </a>
                  </li>
                </ul>
              </nav> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerIndex;
