import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popconfirm, message, Empty } from "antd";
import {
  asyncGetAllPromoCategories,
  asyncGetAllPromo,
  asyncGetAssetPromoCategory,
  asyncDeletePromo,
} from "../../../store/actions/promoActions";
import PromoAdd from "./PromoAdd";
import PromoEdit from "./PromoEdit";
import TableWrapper from "../../../components/Tables";
import Pagination from "../../../components/Pagination";

function Promo() {
  const [pageNumber, setPageNumber] = useState(1);
  const [editedPromoData, setEditedPromoData] = useState(null);
  const [showModalAddPromo, setShowModalPromo] = useState(false);
  const [showModalEditPromo, setShowEditModalPromo] = useState(false);

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncGetAllPromo(pageNumber));
    dispatch(asyncGetAssetPromoCategory());
  }, [dispatch]);

  const dataPromo = useSelector((state) => state.promo);
  const dataPromoCategoryAsset = useSelector(
    (state) => state.assetPromoCategory
  );

  const dataLogin = useSelector((state) => state.login);
  let { userInfo } = dataLogin;

  const tableHead = [
    { name: "Id Promo" },
    { name: "Promo Category" },
    { name: "Promo Title" },
    { name: "Publish Time" },
    { name: "Action" },
  ];

  const tableBody = [
    { render: (item) => item.id },
    { render: (item) => dataPromoCategoryAsset[item.category_id] },
    { render: (item) => item.title },
    {
      render: (item) =>
        new Date(item.created_at).toLocaleString("en-US", {
          timeZone: "Asia/Jakarta",
        }),
    },
    {
      render: (item) => (
        <div align="center">
          {userInfo.user.roles[0].name === "Super Admin" ? (
            <button
              onClick={() => handleShowModalEditPromo(item)}
              className="btn btn-warning btn-sm"
            >
              <i className="fa fa-pencil"></i> Edit{" "}
            </button>
          ) : null}
          {userInfo.user.roles[0].name === "Super Admin" ? (
            <Popconfirm
              title="Are you sure to delete this promo?"
              onConfirm={() => dispatch(asyncDeletePromo(item.id))}
              onCancel={() => message.info("Canceled!")}
              okText="Yes"
              cancelText="No"
            >
              <button className="btn btn-danger btn-sm">
                <i className="fa fa-trash-o"></i> Delete{" "}
              </button>
            </Popconfirm>
          ) : null}
        </div>
      ),
    },
  ];

  const handleShowModalEditPromo = (item) => {
    setEditedPromoData(item);
    setShowEditModalPromo(true);
  };

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Promo</h3>
        </div>
      </div>
      <div className="clearfix"></div>
      <br />
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12">
                  {userInfo.user.roles[0].name === "Super Admin" ? (
                    <button
                      onClick={() => setShowModalPromo(true)}
                      className="btn btn-info btn-sm"
                    >
                      Create New Promo
                    </button>
                  ) : null}
                </div>
              </div>
              <div className="clearfix"></div>
            </div>

            <div className="x_content">
              <TableWrapper 
                datas={dataPromo.data}
                tableHead={tableHead}
                tableBody={tableBody}
              />
              <Pagination datas={dataPromo} listsData={asyncGetAllPromoCategories}/>
            </div>
          </div>
        </div>
      </div>
      {/* Modal add promo */}
      <PromoAdd
        showModalAddPromo={showModalAddPromo}
        setShowModalPromo={setShowModalPromo}
        dataPromoCategoryAsset={dataPromoCategoryAsset}
        dispatch={dispatch}
      />
      {/* Modal edit promo */}
      {editedPromoData && (
        <PromoEdit
          editedPromoData={editedPromoData}
          setEditedPromoData={setEditedPromoData}
          showModalEditPromo={showModalEditPromo}
          setShowEditModalPromo={setShowEditModalPromo}
          dataPromoCategoryAsset={dataPromoCategoryAsset}
          dispatch={dispatch}
        />
      )}
    </div>
  );
}

export default Promo;
