import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  developerList,
  developerDelete,
} from "../../../store/actions/developerActions";
import { Popconfirm, message} from "antd";
import TableWrapper from "../../../components/Tables";
import Pagination from "../../../components/Pagination";

const DeveloperIndex = () => {
  const [search, setsearch] = useState("");
  let dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    dispatch(developerList(1, search));
    localStorage.removeItem("id_developer");
  }, [dispatch]);

  const datadeveloperList = useSelector((state) => state.developerList);
  const { developer, listsDeveloper } = datadeveloperList;

  const dataLogin = useSelector((state) => state.login);
  let { userInfo } = dataLogin;

  const handleEdit = (id) => {
    localStorage.setItem("id_developer", id);
    history.push("/developer-edit");
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(developerList(1, search));
  };

  const tableHead = [
    { name: "No" },
    { name: "Name" },
    { name: "Join Date" },
    { name: "Status" },
    { name: "Action" },
  ];

  const tableBody = [
    { scope: "row" },
    { render: (item) => item.name },
    { render: (item) => item.join_date },

    {
      render: (item) => (
        <div align="center">
          <span
            className={`badge ${
              item.active ? "badge-success" : "badge-danger"
            }`}
          >
            {item.active ? "active" : "deactive"}
          </span>
        </div>
      ),
    },
    {
      render: (item) => (
        <div align="center">
          {userInfo.permission.find((e) => e === "adviser-edit") ? (
            <button
              onClick={() => handleEdit(item.id)}
              className="btn btn-warning btn-sm"
            >
              <i className="fa fa-pencil"></i> Edit{" "}
            </button>
          ) : null}
          {userInfo.permission.find((e) => e === "adviser-delete") ? (
            <Popconfirm
              title="Are you sure to delete this parent?"
              onConfirm={() =>
                dispatch(developerDelete(item.id)).then(() =>
                  dispatch(developerList(1, search))
                )
              }
              onCancel={() => message.info("Canceled!")}
              okText="Yes"
              cancelText="No"
            >
              <button className="btn btn-danger btn-sm">
                <i className="fa fa-trash-o"></i> Delete{" "}
              </button>
            </Popconfirm>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Developer</h3>
        </div>
      </div>
      <div className="clearfix"></div>
      <br />
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12">
                  {userInfo.permission.find((e) => e === "developer-create") ? (
                    <Link
                      to={"/developer-create"}
                      className="btn btn-info btn-sm"
                    >
                      Create
                    </Link>
                  ) : null}
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12 form-group has-feedback">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search By Name"
                    style={{ float: "right", width: "50%" }}
                    onChange={(e) => setsearch(e.target.value)}
                  />
                  <button
                    className="fa fa-search form-control-feedback right btn btn-link"
                    style={{
                      height: "100%",
                      margin: "auto",
                      textDecoration: "none",
                    }}
                    aria-hidden="true"
                    onClick={(e) => handleSearch(e)}
                  ></button>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>

            <div className="x_content">
              <TableWrapper
                datas={listsDeveloper}
                tableHead={tableHead}
                tableBody={tableBody}
              />
              <Pagination
                datas={developer}
                listsData={developerList}
                search={search}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeveloperIndex;
